<template>
<article class="package" v-for="(featuredpackage,index) in featuredpackages" :key="index">
    <div class="package__img">
        <router-link :to="{name:'Packagedetail', params:{slug:featuredpackage.slug,title:featuredpackage.title}}"><img :src="urlPath+'uploads/media/package/thumbnail/326245_'+featuredpackage.featuredimage" alt="image description"></router-link>
        <span class="tag tag-danger">{{ featuredpackage.packagestatus }}</span>
    </div>
    <div class="package__desc">
        <div class="package__desc-group">
            <span class="package__desc-price" v-if="featuredpackage.discountedprice"><sup>US$</sup><strong>{{featuredpackage.discountedprice}}</strong></span>
            <span class="package__desc-price" v-else><sup>US$</sup><strong>{{featuredpackage.regularprice}}</strong></span>
            <span class="package__desc-duration">{{ featuredpackage.tripdays }} days</span>
        </div>
        <h3 class="package__desc-title"><router-link :to="{name:'Packagedetail', params:{slug:featuredpackage.slug,title:featuredpackage.title}}">{{featuredpackage.title}}</router-link></h3>
        <span class="package__desc-activity">Activities: {{featuredpackage.highlightvalue}}</span>
    </div>
</article>

</template>

<script>
import axios from 'axios';
export default {
    name: 'Awesomepackage',
    data() {
        return {
            featuredpackages: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    async mounted() {
        this.featuredPackage();
    },
    methods: {
        featuredPackage() {
            axios.get(this.apiPath+'featuredpackage')
                .then((response) => {
                    this.featuredpackages = response.data;
                })
        },
    }
}
</script>
